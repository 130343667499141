<script lang="ts" setup>
import type { Game } from "@/types";

type GameItem = {
	slug: string;
	type: string;
	type_component: string;
	row_count: number;
};

const props = defineProps<{
	itemSection: number;
}>();

const { pageData, yourPick } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);

const prepareGames = (game: GameItem) => {
	const columns = game.row_count;
	const games = select(pageData.value?.games[camelCase(game?.slug ?? "")]);
	if (columns > 1) {
		return games?.reduce<Array<Array<Game>>>((acc, item, index) => {
			const chunkIndex = Math.floor(index / columns);
			if (!acc[chunkIndex]) {
				acc[chunkIndex] = [];
			}
			acc[chunkIndex].push(item);
			return acc;
		}, []);
	}
	return games;
};
</script>
<template>
	<template v-for="(game, index) in games" :key="index">
		<div v-if="game?.slug === 'last' && !isGuest">
			<OLazyComponent id="yourPickGames" :height="368">
				<LazyOHomeGamesSlider title="your picks" :games="yourPick" route="/issues/favorites/" :is-guest="isGuest" />
			</OLazyComponent>
		</div>
		<OLazyComponent
			v-else-if="
				pageData?.games[camelCase(game?.slug ?? '')]?.length && pageData?.games[camelCase(game?.slug ?? '')]?.length > 0
			"
			:id="`home-game-${game?.slug}`"
			:height="626"
		>
			<LazyOHomeGamesSlider
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
				:games="prepareGames(game)"
				:route="`/issues/${game?.slug}/`"
				:type="game?.type_component"
				:is-guest="isGuest"
			/>
		</OLazyComponent>
	</template>
</template>
